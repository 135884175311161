import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import BlogPost from '../components/organisms/BlogPost/BlogPost';
import { transformContentfulPost } from '../helpers/contentful';
import BlogListsContext from '../context/BlogListsProvider';
import PostListContentful from '../components/organisms/PostListContentful/PostListContentful';

import * as styles from './ContentfulPost.module.css';
import Seo from '../components/organisms/Seo/Seo';

const BCPost = ({ data }) => {
  const [post, setPost] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const blogListsContext = useContext(BlogListsContext);

  useEffect(() => {
    const currentPost = data.postEvents !== null ? data.postEvents : '';
    setPost(currentPost);

    const key =
      data.postEvents !== null
        ? data.postEvents.blog_post_category[0].name
        : '';
    setParentCategory(key);
    if (blogListsContext) {
      const filterPost = [];
      blogListsContext &&
        blogListsContext.events.forEach(item => {
          if (item.name === key) {
            return item.blogItems.map(blog => {
              if (blog.id !== currentPost.id) {
                filterPost.push(blog);
              }
              return blog;
            });
          }
        });
      setRelatedPosts(filterPost.slice(0, 3));
    }
  }, [data, blogListsContext]);

  return (
    <Layout displaySubscribe={true}>
      <Seo
        title={data.postEvents.blogTitle}
        description={data.postEvents.metaDescription}
        image={
          data.postEvents.blogFeaturedImage
            ? `https:${data.postEvents.blogFeaturedImage.file.url}`
            : ''
        }
      />
      {post && (
        <BlogPost
          post={transformContentfulPost(post)}
          category={parentCategory}
        />
      )}
      {relatedPosts && relatedPosts.length > 0 && (
        <div className={styles.relatedPostsContainer}>
          <div className={styles.relatedPostsWrapper}>
            <div className={styles.relatedPostsText}>
              <div className={styles.relatedPostsTitle}>Related posts</div>
              <div className={styles.relatedPostsMeta}>
                These might also interest you
              </div>
            </div>
            <PostListContentful
              posts={relatedPosts}
              viewMode={'grid'}
              cardSize={'large'}
              titleOnly={false}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

BCPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BCPost;

export const query = graphql`
  query ContentfulBlogPostByID($id: String!) {
    postEvents: contentfulBlogPost(id: { eq: $id }) {
      id
      blogTitle
      publishDate
      metaDescription
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      blogFeaturedImage {
        file {
          url
          fileName
        }
      }
      blog_post_category {
        name
      }
      blogContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            file {
              url
              fileName
              details {
                size
              }
            }
            id
          }
        }
      }
      videoUrl
      pdfAttachment {
        file {
          url
          fileName
        }
      }
      updatedAt(formatString: "MMMM DD, YYYY")

      videoPosition {
        videoPosition
      }
    }
  }
`;

