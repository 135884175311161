import React from 'react'
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContentfulAsset from '../ContentfulAsset/ContentfulAsset'
import ContentfulEntry from '../ContentfulEntry/ContentfulEntry'

/**
 * Available Blocks:
    DOCUMENT
    PARAGRAPH
    HEADING_1
    HEADING_2
    HEADING_3
    HEADING_4
    HEADING_5
    HEADING_6
    OL_LIST
    UL_LIST
    LIST_ITEM
    HR
    QUOTE
    EMBEDDED_ENTRY
    EMBEDDED_ASSET
    TABLE
    TABLE_ROW
    TABLE_CELL
    TABLE_HEADER_CELL

 * Available Marks:
    BOLD
    ITALIC
    UNDERLINE
    CODE

 * Available Inlines:
    HYPERLINK
    ENTRY_HYPERLINK
    ASSET_HYPERLINK
    EMBEDDED_ENTRY
 */

// const Bold = ({ children }) => <strong>{children}</strong>
// const Italic = ({ children }) => <em>{children}</em>
const Text = ({ children }) => typeof children[0] === 'string' && children[0].startsWith('##ANCHOR:') ? <div id={children[0].replace('##ANCHOR:', '').trim()} /> : <p>{children}</p>
const Link = ({ node, children }) => node.data.uri.startsWith(process.env.SITE_URL) || !node.data.uri.startsWith('https://') ? <a href={node.data.uri}>{children}</a> : <a href={node.data.uri} target="_blank" rel="noreferrer noopener">{node.content[0]?.value}</a>

const options = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    // [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <ContentfulAsset id={node.data.target.sys.id} />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return (
        <ContentfulEntry id={node.data.target.sys.id} />
      )
    },
    [INLINES.HYPERLINK]: (node, children) => <Link node={node}>{children}</Link>
  },
}

const ContentfulRichText = (raw) => {
    return renderRichText(raw, options)
}

export default ContentfulRichText