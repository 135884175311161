import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '../../atoms/Container/Container';
import * as styles from './BlogPost.module.css';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import ArrowDownLine from '../../atoms/Icon/arrowDown-line';
import NavSocialMediaBlog from '../../molecules/NavSocialMediaBlog/NavSocialMediaBlog';
import ReactDOMServer from 'react-dom/server';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import $ from 'jquery';

const BlogPost = ({ post, category }) => {
  const {
    title,
    published_date: date,
    content,
    thumbnail_path: thumbnail,
    videoUrl,
    videoPosition: { videoPosition },
    pdfAttachment,
  } = post;

  const [videoInfo, setVideoInfo] = useState({ videoId: '', videoService: '' });
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const extractVideoInfo = url => {
      let videoId, videoService;
      if (!url) return { videoId: null, videoService: null };

      if (url.includes('youtube.com')) {
        const urlObj = new URL(url);
        videoId = urlObj.searchParams.get('v');
        videoService = 'youtube';
      } else if (url.includes('vimeo.com')) {
        const pathSegments = url.split('/').filter(Boolean);
        videoId = pathSegments[pathSegments.length - 1];
        videoService = 'vimeo';
      }

      setVideoInfo({ videoId, videoService });
    };

    extractVideoInfo(videoUrl);
  }, [videoUrl]);

  const thumbnailUrl = useMemo(() => {
    return thumbnail ? thumbnail.replace('http:', 'https:') : '';
  }, [thumbnail]);

  useEffect(() => {
    if (blogType && breadcrumbs.length === 0) {
      const breadcrumbData = [
        { link: '/', label: 'Home' },
        { link: '/news-and-views', label: 'News & Views' },
        { label: category },
      ];
      setBreadcrumbs(breadcrumbData);
    }
  }, [category]);

  useEffect(() => {
    $("p:contains('[download-pdf]')").replaceWith(
      pdfAttachment
        ? `<a href="${pdfAttachment}" download target="_blank" rel="noreferrer noopener" class="${styles.downloadPdfButton}">Download PDF</a>`
        : ''
    );
  }, [pdfAttachment]);

  const renderVideoIframe = () => {
    if (!videoInfo.videoId) return null;
    const srcUrl =
      videoInfo.videoService === 'youtube'
        ? `https://www.youtube.com/embed/${videoInfo.videoId}`
        : `https://player.vimeo.com/video/${videoInfo.videoId}`;

    return (
      <div
        style={{
          display: 'block',
          position: 'relative',
          maxWidth: '100%',
          marginTop: '1rem',
        }}>
        <div style={{ paddingTop: '56.25%', marginBottom: '1rem' }}>
          <iframe
            src={srcUrl}
            title='Video'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'
            webkitallowfullscreen='true'
            mozallowfullscreen='true'
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
            }}
          />
        </div>
      </div>
    );
  };

  const blogType = post.type === 'blogPost' ? 'News' : 'Events';

  return (
    <div className={`blogPost ${styles.BlogPostContainer}`}>
      <div
        className={styles.postImageContainer}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>
      <Container>
        <div className={styles.blog}>
          <Breadcrumbs crumbs={breadcrumbs} />

          <div className={styles.postBody}>
            <div className={styles.postTitleWrapper}>
              <div className={styles.postTitle}>{title}</div>
              <div className={styles.postDate}>{date}</div>
            </div>

            {videoPosition === 'top' && renderVideoIframe()}

            <div className={styles.postContent}>
              <ContentfulRichText raw={content} />
            </div>
            <NavSocialMediaBlog isSharing={true} />

            {videoPosition === 'bottom' && renderVideoIframe()}
          </div>
        </div>
      </Container>
    </div>
  );
};

BlogPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    published_date: PropTypes.string,
    content: PropTypes.string,
    thumbnail_path: PropTypes.string,
    videoUrl: PropTypes.string,
    videoPosition: PropTypes.shape({
      videoPosition: PropTypes.string,
    }),
    pdfAttachment: PropTypes.string,
  }).isRequired,
  category: PropTypes.string.isRequired,
};

export default BlogPost;

