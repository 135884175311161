import React, { useMemo, useContext } from 'react';
import Icon from '../../atoms/Icon/Icon'
import * as styles from './NavSocialMediaBlog.module.css'
import ContentfulContext from '../../../context/ContentfulProvider';
import { useWindowDimensions } from "../../../helpers/general";
// TODO: Look to replace this default with data from an external system, in the same structure.
// import defaultSocialItems from './NavSocialMedia-example.json'

const NavSocialMedia = ({ socialItems, label, isSharing }) => {
    const contentful = useContext(ContentfulContext);
    const location = window.location.href;
    const { width } = useWindowDimensions();
    const isMobile = width < 768;
    // console.log(isMobile);
    
    const socialsToRender = useMemo(() => socialItems || (contentful && Object.keys(contentful.socialLinksBlog).map(icon => {
      const socialShareLink = {
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(location),
        linkedIn: (isMobile ? 'https://www.linkedin.com/shareArticle?mini=true&url=' : 'https://www.linkedin.com/sharing/share-offsite/?url=') + encodeURIComponent(location),
        twitter: 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(location),
      };
      
      return {
        "icon": icon,
        "link": isSharing ? socialShareLink[icon] : contentful.socialLinks[icon],
      };
    })) || [], [socialItems, contentful, isSharing, isMobile, location]);
    // console.log(socialsToRender);
    return (
      <div className={styles.blogSocialWrapper}>
        {label && <span className={styles.socialsLabel}>{label}</span>}
        {socialsToRender.map((item, itemIndex) => (
          <a
            href={item.link}
            target="_blank"
            rel="noreferrer"
            key={itemIndex}
            datatype={item.icon}
            className={styles.block}
            onClick={() => window.open(item.link, "pop", "width=600, height=400, scrollbars=no")}
          >
            <Icon symbol={item.icon} fill="#7B2CBF"/>
          </a>
        ))}
      </div>
    );
  };
  
  export default NavSocialMedia;
  
